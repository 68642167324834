import React from "react";
import Diamondeducation from "../components/Diamondeducation"
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const CutPolished = (props) => {
    return (
        <Layout>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <Diamondeducation {...props} fields={props?.data}/>
        </Layout>
    )
};
const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(CutPolished);

export const query = graphql`
fragment CutPolishedFragment on MarkdownRemark {
      frontmatter {
      title
      description
      img{
            publicURL
      }
    }
  }
  query CutPolishedPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "cut-polished-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enCutPolished: markdownRemark(frontmatter: {templateKey: {eq: "en-cut-and-polished"}}) {
   ...CutPolishedFragment
  }
  deCutPolished: markdownRemark(frontmatter: {templateKey: {eq: "de-cut-and-polished"}}) {
   ...CutPolishedFragment
  }
  esCutPolished: markdownRemark(frontmatter: {templateKey: {eq: "es-cut-and-polished"}}) {
   ...CutPolishedFragment
  }
  frCutPolished: markdownRemark(frontmatter: {templateKey: {eq: "fr-cut-and-polished"}}) {
   ...CutPolishedFragment
  }
  heCutPolished: markdownRemark(frontmatter: {templateKey: {eq: "he-cut-and-polished"}}) {
   ...CutPolishedFragment
  }
  itCutPolished: markdownRemark(frontmatter: {templateKey: {eq: "it-cut-and-polished"}}) {
   ...CutPolishedFragment
  }
  jaCutPolished: markdownRemark(frontmatter: {templateKey: {eq: "ja-cut-and-polished"}}) {
   ...CutPolishedFragment
  }
  zhCutPolished: markdownRemark(frontmatter: {templateKey: {eq: "zh-cut-and-polished"}}) {
   ...CutPolishedFragment
  }
 }
`;